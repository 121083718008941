import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Pixelvikings Belgium', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Pixelvikings, your digital partner.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi we are,',
  name: 'Pixelvikings',
  subtitle: 'We provide digital solutions.',
  cta: 'See more.',
};

// ABOUT DATA
export const aboutData = {
  img: 'logo.png',
  paragraphOne: 'Web, mobile, desktop, embedded, iot,... ',
  paragraphTwo: 'We have done it all, take a look at our previous projects below.',
  paragraphThree: 'If you are in need of a digital partner for company, feel free to contact us.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'summerbash.jpeg',
    title: 'Summer Bash Events',
    info: 'We develop and support Summer Bash as a tecnical partner so they can provide their traveller with the best experience possible.',
    info2:
      'This project has involved custom management systems, websites, mobile apps and general IT support.',
    url: 'https://www.summerbash.be',
  },
  {
    id: nanoid(),
    img: 'enygma.png',
    title: 'Enygma escape rooms',
    info: 'Located in the center of Brussels, enygma provides a multitude of interesting escape room experiences.',
    info2: 'We developed interactive solutions for this escape room using c# and the unity engine.',
    url: 'https://www.enygma.be/en/rooms/nautilus',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mediahuis.jpeg',
    title: 'Mediahuis',
    info: 'Developed a new fragment system to support a multitude of news brands from one single platform.',
    info2: '',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'eward@pixelvikings.be',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
